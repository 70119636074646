import { Injectable } from '@angular/core'

import * as _ from 'lodash'
import { PagingDto } from '../dto/paging.dto'

import { FindProductReviewDto, PageResultDto, ProductReviewDto, ProductReviewPagingDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class ProductReviewService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({searchText, limit, offset }: FindProductReviewDto) {
    return await this.httpService.get<PageResultDto<ProductReviewDto>>(`/merchant/v1/products/reviews`, {searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductReviewDto>(`/merchant/v1/products/reviews/${id}`)
  }

  async findByProductId(productId: string) {
    return await this.httpService.get<ProductReviewPagingDto>(`/merchant/v1/products/${productId}/reviews`)
  }
}
