import { Injectable } from "@angular/core"

import { PageResultDto, MerchantDeleteCollectionItemDto, FlashSaleDto, FindFlashSaleDto, FlashSaleItemDto, CreateFlashSaleItemDto, ProductDto, FindFlashSaleProductDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class FlashSaleService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findFlashSaleDto: FindFlashSaleDto) {
    return await this.httpService.get<PageResultDto<FlashSaleDto>>('/merchant/v1/flash-sale', findFlashSaleDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<FlashSaleDto>(`/merchant/v1/flash-sale/${id}`)
  }

  async join(id: string, discount: number) {
    return await this.httpService.post<number>(`/merchant/v1/flash-sale/${id}/join`, { discount })
  }

  async findItems(id: string, pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<FlashSaleItemDto>>(`/merchant/v1/flash-sale/${id}/items`, pagingDto)
  }

  async findProduct(findFlashSaleProductDto: FindFlashSaleProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/flash-sale/products`, findFlashSaleProductDto)
  }

  async createItems(id: string, { isAll, productIds, discount }: CreateFlashSaleItemDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/flash-sale/${id}/items`, { isAll, productIds, discount })
  }

  async deleteItem(id: string, { productIds }: MerchantDeleteCollectionItemDto) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/flash-sale/${id}/items`, { productIds })
  }

  async leave(id: string) {
    return await this.httpService.post<Boolean>(`/merchant/v1/flash-sale/${id}/leave`)
  }
}
