import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateLocationDto, FindLocationDto, LocationDto, UpdateLocationDto } from '../dto'

@Injectable()
export class AdminLocationService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findLocationDto: FindLocationDto) {
    return await this.httpService.get<LocationDto[]>(`/admin/v1/locations`, findLocationDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<LocationDto>(`/admin/v1/locations/${id}`)
  }

  async create({ name }: CreateLocationDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/locations`, { name })
  }

  async update(id: string, { name, enabled }: UpdateLocationDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/locations/${id}`, { name, enabled })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/locations/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/locations/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/locations/${id}/disable`)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/locations/sort-order`, { ids })
  }
}
