import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { BannerPopupDto, CreateBannerPopupDto, FindBannerPopupDto, PageResultDto, UpdateBannerPopupDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminBannerPopupService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset, type }: FindBannerPopupDto) {
    return await this.httpService.get<PageResultDto<BannerPopupDto>>(`/admin/v1/banner-popup`, { limit, offset, type })
  }

  async update(id: string, updateBannerOnboardDto: UpdateBannerPopupDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/banner-popup/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerPopupDto: CreateBannerPopupDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/banner-popup`, createBannerPopupDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/banner-popup/${id}`)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/banner-popup/sort-order`, { ids })
  }

  
  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/banner-popup/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/banner-popup/${id}/disable`)
  }
}
