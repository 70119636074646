

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { ShippingProviderDto, ShippingRateDto, ShippingZoneDto, UpdateShippingProviderDto, UpdateShippingRateDto, UpdateShippingZoneDto } from '../dto'

@Injectable()
export class AdminShippingService {
  constructor(
    private httpService: HttpService) {
  }
  
  async find() {
    return await this.httpService.get<ShippingProviderDto[]>(`/admin/v1/shippings/providers`)
  }

  async update(id: string, updateShippingProviderDto: UpdateShippingProviderDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/shippings/providers/${id}`, updateShippingProviderDto)
  }

  async zones() {
    return await this.httpService.get<ShippingZoneDto[]>(`/admin/v1/shippings/zones`)
  }

  async zoneDetail(zoneId: string) {
    return await this.httpService.get<ShippingZoneDto>(`/admin/v1/shippings/zones/${zoneId}`)
  }

  async updateZone(zoneId: string, updateShippingZoneDto: UpdateShippingZoneDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/shippings/zones/${zoneId}`, updateShippingZoneDto)
  }

  async enableZone(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/shippings/zones/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/shippings/zones/${id}/disable`)
  }

  async updateCoverage(zoneId: string, updateShippingZoneDto: UpdateShippingZoneDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/shippings/zones/${zoneId}/coverage`, updateShippingZoneDto)
  }

  async availableRates(city: string) {
    return await this.httpService.get<ShippingRateDto[]>(`/admin/v1/shippings/rates/available`, { city })
  }

  async rates(shippingZoneId: string) {
    return await this.httpService.get<ShippingRateDto[]>(`/admin/v1/shippings/rates`, { shippingZoneId })
  }

  async updateRate(rateId: string, updateShippingRateDto: UpdateShippingRateDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/shippings/rates/${rateId}`, updateShippingRateDto)
  }

  async enableRate(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/shippings/rates/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/shippings/rates/${id}/disable`)
  }
}
