import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, CreateUserDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminUserService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/admin/v1/users', findUserDto)
  }

  async findDeleted(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/admin/v1/users/deleted-customers', findUserDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<UserDto>(`/admin/v1/users/${id}`)
  }

  async findTenantControl(id: string) {
    return await this.httpService.get<string[]>(`/admin/v1/users/${id}/tenant-control`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch(`/admin/v1/users/${id}/enable`)
    }
    
    return await this.httpService.patch(`/admin/v1/users/${id}/disable`)
  }

  async export(findUserDto: FindUserDto) {
    return await this.httpService.get<any>(`/admin/v1/users/export`, findUserDto)
  }

  async changePassword(id: string, newPassword: string) {
    return await this.httpService.patch(`/admin/v1/users/${id}/password`, { password: newPassword })
  }

  async findByIds(ownerIds: string) {
    return await this.httpService.post<UserDto[]>(`/admin/v1/users/ids`, { ownerIds })
  }

  async create(createUserDto: CreateUserDto) {
    return await this.httpService.post<UserDto>('/admin/v1/users', createUserDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/users/${id}`)
  }
}
