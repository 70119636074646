import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { PageResultDto, ProductDto, FindProductDto, ProductDetailDto, CreateOrUpdateProductDto, SetFileDto, SetFilesDto, ExportProductDto, ProductReviewPagingDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminProductService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/admin/v1/products`, findProductDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductDetailDto>(`/admin/v1/products/${id}`)
  }

  async findByReferenceCode(code: string, tenantId: string) {
    return await this.httpService.get<ProductDetailDto>(`/admin/v1/products/reference-code`, { code, tenantId })
  }

  async import(file: File, tenantId: string) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('tenantId', tenantId)

    return await this.httpService.post<Boolean>(`/admin/v1/products/import`, formData, config)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/admin/v1/products/${id}/enable`)
    }
    
    return await this.httpService.put<Boolean>(`/admin/v1/products/${id}/disable`)
  }

  async export(exportProductDto: ExportProductDto) {
    return await this.httpService.get<any>(`/admin/v1/products/export`, exportProductDto)
  }

  async setThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/thumbnail`, setFileDto)
  }

  async setColorThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/color-thumbnail`, setFileDto)
  }

  async setImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/images`, setFilesDto)
  }

  async setSpecsImage(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/specs-images`, setFilesDto)
  }
}
