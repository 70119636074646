import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CustomerInquiryDto, FindCustomerInquiryDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminCustomerInquiryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findCustomerInquiry: FindCustomerInquiryDto) {
    return await this.httpService.get<PageResultDto<CustomerInquiryDto>>(`/admin/v1/customer-inquiry`, findCustomerInquiry)
  }

  async findOne(id: string) {
    return await this.httpService.get<CustomerInquiryDto>(`/admin/v1/customer-inquiry/${id}`)
  }
}
