import { Injectable } from "@angular/core"

import { FindGalleryDto, PageResultDto, GalleryDto, CreateGalleryDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class AdminGalleryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset, searchText, mallId }: FindGalleryDto) {
    return await this.httpService.get<PageResultDto<GalleryDto>>('/admin/v1/galleries', { limit, offset, searchText, mallId })
  }

  async findOne(id: string) {
    return await this.httpService.get<GalleryDto>(`/admin/v1/galleries/${id}`)
  }

  async create(CreateGalleryDto: CreateGalleryDto) {
    return await this.httpService.post<GalleryDto>('/admin/v1/galleries', CreateGalleryDto)
  }

  async update(id: string, updateGalleryDto: CreateGalleryDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/galleries/${id}`, updateGalleryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/galleries/${id}`)
  }

  async updateThumbnail(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/galleries/${id}/thumbnail`, { fileId })
  }

  async sendNotification(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/galleries/${id}/notification`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/galleries/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/galleries/${id}/disable`)
  }
}