import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateSizeChartNameDto, FindSizeChartDto, PageResultDto, SizeChartDto, SizeChartNameDto } from '../dto'

@Injectable()
export class SizeChartService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findSizeChartDto: FindSizeChartDto) {
    return await this.httpService.get<PageResultDto<SizeChartNameDto>>('/merchant/v1/size-chart', findSizeChartDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<SizeChartNameDto>(`/merchant/v1/size-chart/${id}`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/size-chart/${id}`)
  }

  async create(createSizeChartNameDto: CreateSizeChartNameDto) {
    return await this.httpService.post<SizeChartNameDto>(`/merchant/v1/size-chart`, createSizeChartNameDto)
  }

  async update(id: string, createSizeChartNameDto: CreateSizeChartNameDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/size-chart/${id}`, createSizeChartNameDto)
  }

  async findType(findSizeChartDto: FindSizeChartDto) {
    return await this.httpService.get<PageResultDto<SizeChartDto>>('/merchant/v1/size-chart/types', findSizeChartDto)
  }

  async findOneType(id: string) {
    return await this.httpService.get<SizeChartDto>('/merchant/v1/size-chart/types/' + id)
  }
}
