

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { AdminCreateSizeChartDto, AdminCreateSizeChartTitleDto, AdminFindSizeChartDto, AdminSizeChartDto, AdminSizeChartTitleDto, AdminUpdateSizeChartDto, PageResultDto } from '../dto'

@Injectable()
export class AdminSizeChartService {
  constructor(
    private httpService: HttpService) {
  }
  
  async find(adminFindSizeChartDto: AdminFindSizeChartDto) {
    return await this.httpService.get<PageResultDto<AdminSizeChartDto>>(`/admin/v1/size-chart`, adminFindSizeChartDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminSizeChartDto>(`/admin/v1/size-chart/${id}`)
  }

  async create(adminCreateSizeChartDto: AdminCreateSizeChartDto) {
    return await this.httpService.post<AdminSizeChartDto>(`/admin/v1/size-chart`, adminCreateSizeChartDto)
  }

  async update(id: string, adminUpdateSizeChartDto: AdminUpdateSizeChartDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/size-chart/${id}`, adminUpdateSizeChartDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/size-chart/${id}`)
  }

  async findTitle(id: string) {
    return await this.httpService.get<AdminSizeChartTitleDto[]>(`/admin/v1/size-chart/${id}/titles`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/size-chart/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/size-chart/${id}/disable`)
  }

  async sortOrderTitle(id: string, ids: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/size-chart/${id}/titles/sort-order`, { ids })
  }

  async createTitle(id: string, adminCreateSizeChartTitleDto: AdminCreateSizeChartTitleDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/size-chart/${id}/titles`, adminCreateSizeChartTitleDto)
  }

  async deleteTitle(id: string, sizeChartTitleId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/size-chart/${id}/titles/${sizeChartTitleId}`)
  }
}
