import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { HttpService } from './http.service'

import { AdminUpdateTenantResponseDto, CreateMallAdminDto, CreateTenantDto, FindTenantDto,  PageResultDto, SortOrderTenantDto, TenantDto, UpdateTenantDto } from '../dto'

@Injectable()
export class AdminTenantService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findTenantDto: FindTenantDto) {
    return await this.httpService.get<PageResultDto<TenantDto>>(`/admin/v1/tenants`, findTenantDto)
  }

  async create(createTenantDto: CreateTenantDto) {
    return await this.httpService.post<TenantDto>(`/admin/v1/tenants`, createTenantDto)
  }

  async sortOrder(sortOrderDto: SortOrderTenantDto) {
    return await this.httpService.post<any>(`/admin/v1/tenants/sort-order`, sortOrderDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantDto>(`/admin/v1/tenants/${id}`)
  }

  async findByIds(ids: string[]) {
    return await this.httpService.get<TenantDto[]>(`/admin/v1/tenants/ids`, { tenantIds: ids })
  }

  async update(id: string, updateTenantDto: UpdateTenantDto) {
    return await this.httpService.put<AdminUpdateTenantResponseDto>(`/admin/v1/tenants/${id}`, updateTenantDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/tenants/${id}`)
  }

  async export(email: string) {
    return await this.httpService.get<any>(`/admin/v1/tenants/export`, { email })
  }

  async createAdmin(id: string, createMallAdminDto: CreateMallAdminDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenants/${id}/admin`, createMallAdminDto)
  }

  async updateLogo(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/logo`, { fileId })
  }

  async updateCover(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/cover`, { fileId })
  }

  async updateMobileCover(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/mobile-cover`, { fileId })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/disable`)
  }

  async setAdmin(id: string, ownerIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenants/${id}/admins`, { ownerIds })
  }

  async setTenantControl(userId: string, tenantIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenants/control`, { ownerId: userId, tenantIds })
  }
}
