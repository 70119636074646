import { Injectable } from '@angular/core'

import { FindMallVoucherDto, PageResultDto, TenantVoucherDetailDto, TenantVoucherDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminTenantVoucherService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindMallVoucherDto) {
    return await this.httpService.get<PageResultDto<TenantVoucherDetailDto>>(`/admin/v1/tenant-vouchers`, findVoucherDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantVoucherDetailDto>(`/admin/v1/tenant-vouchers/${id}`)
  }

  async approve(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenant-vouchers/${id}/approve`)
  }

  async reject(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenant-vouchers/${id}/reject`)
  }
}
