import { Injectable } from '@angular/core'

import { FindMallVoucherDto, MallVoucherDto, PageResultDto } from '../dto'
import { CreateMallVoucherDto, ExportHistoryDto, MallVoucherDetailDto, VoucherHistoryDto } from '../dto/mall-voucher.dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminMallVoucherService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindMallVoucherDto) {
    return await this.httpService.get<PageResultDto<MallVoucherDto>>(`/admin/v1/mall-vouchers`, findVoucherDto)
  }

  async findHistory(findMallVoucherDto: FindMallVoucherDto) {
    return await this.httpService.get<PageResultDto<VoucherHistoryDto>>(`/admin/v1/mall-vouchers/history`, findMallVoucherDto)
  }

  async exportHistory(exportHistoryDto: ExportHistoryDto) {
    return await this.httpService.get<any>(`/admin/v1/mall-vouchers/export-history`, exportHistoryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<MallVoucherDetailDto>(`/admin/v1/mall-vouchers/${id}`)
  }

  async create(createVoucherDto: CreateMallVoucherDto) {
    return await this.httpService.post<MallVoucherDto>('/admin/v1/mall-vouchers', createVoucherDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/mall-vouchers/${id}`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/mall-vouchers/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/mall-vouchers/${id}/disable`)
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<string[]>(`/admin/v1/mall-vouchers/import-customer`, formData, config)
  }
}
