import { Injectable } from '@angular/core'

import { CreateProductDiscountDto, FindFlashSaleProductDto, FindProductDiscountDto, PageResultDto, ProductDiscountDto, ProductDto, UpdateProductDiscountDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class ProductDiscountService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: FindProductDiscountDto) {
    return await this.httpService.get<PageResultDto<ProductDiscountDto>>(`/merchant/v1/products-discount`, pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductDiscountDto>(`/merchant/v1/products-discount/${id}`)
  }

  async findProduct(findFlashSaleProductDto: FindFlashSaleProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/products-discount/products`, findFlashSaleProductDto)
  }

  async create(createDiscountProductCollectionDto: CreateProductDiscountDto) {
    return await this.httpService.post<ProductDiscountDto>(`/merchant/v1/products-discount`, createDiscountProductCollectionDto)
  }

  async update(id: string, updateDiscountProductCollectionDto: UpdateProductDiscountDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/products-discount/${id}`, updateDiscountProductCollectionDto)
  }

  async updateItems(id: string, productIds: string, isAll: string) {
    return await this.httpService.post<Boolean>(`/merchant/v1/products-discount/items`, { productDiscountId: id, productIds, isAll })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/products-discount/${id}`)
  }

  async deleteItem(id: string, productId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/products-discount/${id}/items/${productId}`)
  }
}