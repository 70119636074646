import { Injectable } from "@angular/core"
import { ResultDto } from "@api/dto"

import { HttpService } from "./http.service"

import { MerchantTenantExportDto, MerchantVoucherExportDto } from "../dto/merchant-report.dto"


@Injectable()
export class MerChantReportSerive {
  constructor(
    private httpService: HttpService
  ) {
  }

  async exportOrder(tenantOrder: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-report', tenantOrder)
  }

  async exportFinancialOrder(tenantOrder: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-financial-report', tenantOrder)
  }

  async exportProductSaleOrder(merchantTenantExoprtDto: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-product-sale-report', merchantTenantExoprtDto)
  }

  async exportProductSaleOrderByTransactionDate(merchantTenantExoprtDto: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-product-sale-report-by-transaction-date', merchantTenantExoprtDto)
  }

  async exportVoucher(voucherOrder: MerchantVoucherExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/tenant-vouchers/export-report', voucherOrder)
  }
}