import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AndroidAppVersionDto, AppVersionDto, CreateAppVersionDto, PageResultDto, SubmitAndroidPublisherTrackDto, UpdateAppVersionDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminAppVersionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<AppVersionDto>>(`/admin/v1/app-version`, { limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<AppVersionDto>(`/admin/v1/app-version/${id}`)
  }

  async update(id: number, updateAppVersionDto: UpdateAppVersionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/app-version/${id}`, updateAppVersionDto)
  }

  async create(createAppVersionDto: CreateAppVersionDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/app-version`, createAppVersionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/app-version/${id}`)
  }

  async setEnable(id: number, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/app-version/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/app-version/${id}/disable`)
  }

  async findTrack() {
    return await this.httpService.get<AndroidAppVersionDto>(`/admin/v1/app-version/androidpublisher-tracks`)
  }

  async submitTrack(createAppVersionDto: SubmitAndroidPublisherTrackDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/app-version/androidpublisher-tracks`, createAppVersionDto)
  }
}
