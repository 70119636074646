import { Injectable } from '@angular/core'

import { CreateTenantVoucherDto, FindTenantVoucherDto, MallVoucherDto, PageResultDto, TenantVoucherDetailDto, TenantVoucherDto, TenantVoucherHistoryDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class TenantVoucherService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindTenantVoucherDto) {
    return await this.httpService.get<PageResultDto<TenantVoucherDto>>(`/merchant/v1/tenant-vouchers`, findVoucherDto)
  }

  async findHistory(pagingDto: FindTenantVoucherDto) {
    return await this.httpService.get<PageResultDto<TenantVoucherHistoryDto>>(`/merchant/v1/tenant-vouchers/history`, pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantVoucherDetailDto>(`/merchant/v1/tenant-vouchers/${id}`)
  }

  async create(createVoucherDto: CreateTenantVoucherDto) {
    return await this.httpService.post<MallVoucherDto>('/merchant/v1/tenant-vouchers', createVoucherDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/tenant-vouchers/${id}`)
  }
}
