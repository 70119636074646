import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateTenantCategoryDto, FindTenantCategoryDto, PageResultDto, TenantCategoryDto, UpdateTenantCategoryDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminTenantCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findMallDto: FindTenantCategoryDto) {
    return await this.httpService.get<PageResultDto<TenantCategoryDto>>(`/admin/v1/tenant-categories`, findMallDto)
  }

  async create(createTenantCategoryDto: CreateTenantCategoryDto) {
    return await this.httpService.post<TenantCategoryDto>(`/admin/v1/tenant-categories`, createTenantCategoryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantCategoryDto>(`/admin/v1/tenant-categories/${id}`)
  }

  async update(id: string, updateTenantCategoryDto: UpdateTenantCategoryDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenant-categories/${id}`, updateTenantCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/tenant-categories/${id}`)
  }

  async updateImage(id: string, fileId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenant-categories/${id}/image`, { fileId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/tenant-categories/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/tenant-categories/${id}/disable`)
  }
}
