import { Injectable } from '@angular/core'

import { CreateMallServicePageDto, FindMallServicePageDto, MallServicePageDto, PageDto, PageResultDto, UpdateMallServicePageDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class AdminMallServicePageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: FindMallServicePageDto) {
    return await this.httpService.get<PageResultDto<MallServicePageDto>>('/admin/v1/mall-service-pages', findPageDto)
  }

  async findOne(pageId: string) {
    return await this.httpService.get<MallServicePageDto>(`/admin/v1/mall-service-pages/${pageId}`)
  }

  async update(pageId: string, updatePageDto: UpdateMallServicePageDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/mall-service-pages/${pageId}`, updatePageDto)
  }

  async delete(pageId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/mall-service-pages/${pageId}`)
  }

  async create(createMallServicePageDto: CreateMallServicePageDto) {
    return await this.httpService.post<PageDto>(`/admin/v1/mall-service-pages`, createMallServicePageDto)
  }
}
