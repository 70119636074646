import { Injectable } from '@angular/core'

import { FindPageDto, PageDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class AdminPageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: FindPageDto) {
    return await this.httpService.get<PageDto[]>('/admin/v1/pages', findPageDto)
  }

  async findOne(pageId: string) {
    return await this.httpService.get<PageDto>(`/admin/v1/pages/${pageId}`)
  }

  async updateImage(pageId: string,imageId: string) {
    return await this.httpService.put(`/admin/v1/pages/${pageId}/image`, { "fileId": imageId })
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/v1/pages/${pageId}`, updatePageDto)
  }
}
