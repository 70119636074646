import { Injectable } from "@angular/core"

import { PageResultDto, UpdateBeginnerGuideDto, CreateBeginnerGuideDto, BeginnerGuideDto, UpdateBeginnerGuideItemDto, CreateBeginnerGuideItemDto, BeginnerGuideItemDto, PageDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class AdminBeginnerGuideService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<BeginnerGuideDto>>('/admin/v1/beginner-guide', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BeginnerGuideDto>(`/admin/v1/beginner-guide/${id}`)
  }

  async findPage() {
    return await this.httpService.get<PageDto[]>('/admin/v1/beginner-guide/pages')
  }

  async update(id: string, updateBeginnerGuideDto: UpdateBeginnerGuideDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/beginner-guide/${id}`, updateBeginnerGuideDto)
  }

  async create(createBeginnerGuideDto: CreateBeginnerGuideDto) {
    return await this.httpService.post<BeginnerGuideDto>(`/admin/v1/beginner-guide`, createBeginnerGuideDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/beginner-guide/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/beginner-guide/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/beginner-guide/${id}/disable`)
  }

  async sortOrder(ids: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/beginner-guide/sort-order`, { beginnerGuideIds: ids })
  }

  async sortOrderItem(beginnerGuideId: string, ids: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/beginner-guide/items/sort-order`, { beginnerGuideId, beginnerGuideItemIds: ids })
  }

  async updateItem(id: string, updateBeginnerGuideDto: UpdateBeginnerGuideItemDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/beginner-guide/items/${id}`, updateBeginnerGuideDto)
  }

  async createItem(createBeginnerGuideDto: CreateBeginnerGuideItemDto) {
    return await this.httpService.post<BeginnerGuideItemDto>(`/admin/v1/beginner-guide/items`, createBeginnerGuideDto)
  }

  async deleteItem(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/beginner-guide/items/${id}`)
  }

  async setEnabledItem(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/beginner-guide/items/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/beginner-guide/items/${id}/disable`)
  }
}