import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { BannerOnboardDto, CreateBannerOnboardDto, PageResultDto, UpdateBannerOnboardDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminBannerOnboardService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<BannerOnboardDto>>(`/admin/v1/banners-onboard`, { limit, offset })
  }

  async update(id: string, updateBannerOnboardDto: UpdateBannerOnboardDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/banners-onboard/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerOnboardDto: CreateBannerOnboardDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/banners-onboard`, createBannerOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/banners-onboard/${id}`)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/banners-onboard/sort-order`, { ids })
  }
}
