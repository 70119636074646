import { Injectable } from '@angular/core'

import { CreateMallCouponDto, ExportHistoryDto, FindMallCouponDto, FindMallVoucherDto, MallCouponDto, MallCouponHistoryDto, PageResultDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminMallCouponService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindMallCouponDto) {
    return await this.httpService.get<PageResultDto<MallCouponDto>>(`/admin/v1/mall-coupons`, findVoucherDto)
  }

  async findHistory(findMallVoucherDto: FindMallVoucherDto) {
    return await this.httpService.get<PageResultDto<MallCouponHistoryDto>>(`/admin/v1/mall-coupons/history`, findMallVoucherDto)
  }

  async exportHistory(exportHistoryDto: ExportHistoryDto) {
    return await this.httpService.get<any>(`/admin/v1/mall-coupons/export-history`, exportHistoryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<MallCouponDto>(`/admin/v1/mall-coupons/${id}`)
  }

  async create(createVoucherDto: CreateMallCouponDto) {
    return await this.httpService.post<MallCouponDto>('/admin/v1/mall-coupons', createVoucherDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/mall-coupons/${id}`)
  }

  async exportCode(id: string) {
    return await this.httpService.get<any>(`/admin/v1/mall-coupons/${id}/export-code`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/mall-coupons/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/mall-coupons/${id}/disable`)
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<string[]>(`/admin/v1/mall-vouchers/import-customer`, formData, config)
  }
}
