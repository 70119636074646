import { Injectable } from '@angular/core'

import { FloorMapDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class FloorMapService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<FloorMapDto[]>('/merchant/v1/floor-map')
  }

  async update(id: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/floor-map/${id}/tenant`)
  }
}
