import { Injectable } from '@angular/core'

import { AdminFindPageContentDto, AdminPageContentDto, AdminPageContentItemDto, AdminUpdatePageContentDto, AdminUpdatePageContentElementDto, AdminUpdatePageContentItemDto, FindPageDto, PageDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminPageContentService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: AdminFindPageContentDto) {
    return await this.httpService.get<AdminPageContentDto[]>('/admin/v1/page-content', findPageDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminPageContentDto>(`/admin/v1/page-content/${id}`)
  }

  async findOneItem(id: string) {
    return await this.httpService.get<AdminPageContentItemDto>(`/admin/v1/page-content/items/${id}`)
  }

  async updateOneItem(id: string, adminUpdatePageContentItemDto: AdminUpdatePageContentItemDto) {
    return await this.httpService.put<AdminPageContentItemDto>(`/admin/v1/page-content/items/${id}`, adminUpdatePageContentItemDto)
  }

  async updateOneElement(id: string, adminUpdatePageContentItemDto: AdminUpdatePageContentElementDto) {
    return await this.httpService.put<AdminPageContentItemDto>(`/admin/v1/page-content/items/elements/${id}`, adminUpdatePageContentItemDto)
  }

  async update(id: string, updatePageDto: AdminUpdatePageContentDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/page-content/${id}`, updatePageDto)
  }
}
