import { Injectable } from "@angular/core"

import { CreatePickupCounterDto, FindPickupCounterDto, PageResultDto, PickupCounterDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class AdminPickupCounterService {
  constructor(
    private httpService: HttpService){
  }

  async find({ limit, offset, mallId }: FindPickupCounterDto) {
    return await this.httpService.get<PageResultDto<PickupCounterDto>>('/admin/v1/pickup-counter', { limit, offset, mallId })
  }

  async findOne(id: string) {
    return await this.httpService.get<PickupCounterDto>(`/admin/v1/pickup-counter/${id}`)
  }

  async create(createPickupCounterDto: CreatePickupCounterDto) {
    return await this.httpService.post<PickupCounterDto>('/admin/v1/pickup-counter', createPickupCounterDto)
  }

  async update(id: string, updatePickupCounterDto: CreatePickupCounterDto) {
    return await this.httpService.put<boolean>(`/admin/v1/pickup-counter/${id}`, updatePickupCounterDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/pickup-counter/${id}`)
  }
}