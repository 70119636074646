import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateMallServiceMenuDto, FindMallServiceMenuDto, MallServiceMenuDto, PageResultDto } from '../dto'

@Injectable()
export class AdminMallServiceMenuService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findMenuDto: FindMallServiceMenuDto) {
    return await this.httpService.get<PageResultDto<MallServiceMenuDto>>('/admin/v1/mall-service-menu', findMenuDto)
  }

  async update(id: string, createMallServiceMenuDto: CreateMallServiceMenuDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/mall-service-menu/${id}`, createMallServiceMenuDto)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/mall-service-menu/sort-order`, { ids })
  }

  async create(createMallServiceMenuDto: CreateMallServiceMenuDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/mall-service-menu`, createMallServiceMenuDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/mall-service-menu/${id}`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/mall-service-menu/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/mall-service-menu/${id}/disable`)
  }
}
