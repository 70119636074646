import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateFeaturedTenantCategoryDto, FindByMallId, FindProductDiscountTenantDto, ProductDiscountTenantDto, TenantCategoryDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminFeaturedTenantCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findMallDto: FindByMallId) {
    return await this.httpService.get<TenantCategoryDto[]>(`/admin/v1/tenant-categories/featured`, findMallDto)
  }

  async create(createFeaturedTenantDto: CreateFeaturedTenantCategoryDto) {
    return await this.httpService.post<TenantCategoryDto>(`/admin/v1/tenant-categories/featured`, createFeaturedTenantDto)
  }

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenant-categories/featured/sort-order`, { categoryIds })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/tenant-categories/featured/${categoryId}`)
  }

  async setEnable(categoryId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/tenant-categories/featured/${categoryId}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/tenant-categories/featured/${categoryId}/disable`)
  }
}
