import { Injectable } from '@angular/core'

import { PageResultDto, VoucherCampaignDto } from '../dto'

import { HttpService } from './http.service'
import { UpdateVoucherCampaignDto } from '../dto/voucher-campaign.dto'
import { PagingDto } from '../dto/paging.dto'

@Injectable()
export class AdminVoucherCampaignService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<VoucherCampaignDto>>('/admin/v1/voucher-campaigns', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<VoucherCampaignDto>(`/admin/v1/voucher-campaigns/${id}`)
  }

  async update(id: string, updateVoucherCampaignDto: UpdateVoucherCampaignDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/voucher-campaigns/${id}`, updateVoucherCampaignDto)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch(`/admin/v1/voucher-campaigns/${id}/enable`)
    }
    
    return await this.httpService.patch(`/admin/v1/voucher-campaigns/${id}/disable`)
  }
}
