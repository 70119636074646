import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { FindProductDiscountTenantDto, PageResultDto, AdminProductDiscountDto, ProductDiscountTenantDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminProductDiscountService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findDiscount: FindProductDiscountTenantDto) {
    return await this.httpService.get<PageResultDto<AdminProductDiscountDto>>(`/admin/v1/products-discount`, findDiscount)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminProductDiscountDto>(`/admin/v1/products-discount/${id}`)
  }

  async findTenant(findDiscount: FindProductDiscountTenantDto) {
    return await this.httpService.get<PageResultDto<ProductDiscountTenantDto>>(`/admin/v1/products-discount/tenants`, findDiscount)
  }

  async sortOrder(tenantIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/products-discount/sort-order`, { tenantIds })
  }

  async setSortEnable(tenantId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/products-discount/${tenantId}/enable-sort`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/products-discount/${tenantId}/disable-sort`)
  }

  async approve(productDiscountId: string, tenantId: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/products-discount/${tenantId}/approve`, { productDiscountId })
  }

  async reject(productDiscountId: string, tenantId: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/products-discount/${tenantId}/reject`, { productDiscountId })
  }
}
