import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { PageResultDto } from './../dto'
import { AddminFindProductReviewDto, AdminProductReviewDto } from './../dto/admin-product-review.dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminProductReviewService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ tenantId, limit, offset, searchText }: AddminFindProductReviewDto) {
    return await this.httpService.get<PageResultDto<AdminProductReviewDto>>(`/admin/v1/products/reviews`, { limit, offset, tenantId, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminProductReviewDto>(`/admin/v1/products/reviews/${id}`)
  }

  async findByProductId(productId: string) {
    return await this.httpService.get<AdminProductReviewDto>(`/admin/v1/products/${productId}/reviews`)
  }

}
