import { Injectable } from '@angular/core'

import { CreateProductCategoryDto, PageResultDto, ProductCategoryDto, UpdateProductCategoryDto } from '../dto'
import { FindProductCategoryDto } from '../dto/product-category.dto'

import { HttpService } from './http.service'


@Injectable()
export class ProductCategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async find() {
    return await this.httpService.get<ProductCategoryDto[]>('/merchant/v1/product-categories')
  }

  async findLastChildren() {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/merchant/v1/product-categories/last-children')
  }

  async findChildren(searchText: string) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/merchant/v1/product-categories/last-children', {searchText})
  }

  async findWithParent(searchText: string, limit: number = 10, offset: number = 0) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/merchant/v1/product-categories/parent', {searchText, limit, offset})
  }

  async findOne(categoryId: string) {
    return await this.httpService.get<ProductCategoryDto>(`/merchant/v1/product-categories/${categoryId}`)
  }

  async update(categoryId: string, { name, imageId, mobileImageId }: UpdateProductCategoryDto) {
    return await this.httpService.patch<Boolean>(`/merchant/v1/product-categories/${categoryId}`, {
      name,
      imageId,
      mobileImageId
    })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/product-categories/${categoryId}`)
  }

  async create({ name, imageId, mobileImageId, parentId  }: CreateProductCategoryDto) {
    return await this.httpService.post<Boolean>('/merchant/v1/product-categories', {
      name, 
      imageId, 
      mobileImageId, 
      parentId
    })
  }
}