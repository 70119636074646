import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { SettingDto, UpdateSettingDto } from '../dto'

@Injectable()
export class SettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<SettingDto[]>('/admin/v1/setting')
  }

  async findOneValue(key: string) {
    return await this.httpService.get<SettingDto>(`/admin/v1/setting/${key}`)
  }

  async update(id: string, settingDto: UpdateSettingDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/setting/${id}`, settingDto)
  }

  async findOne(key: string) {
    return await this.httpService.get<SettingDto>(`/v1/settings/${key}`)
  }
}
