import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateMallAdminDto, CreateMallDto, FindMallDto, MallDto, PageResultDto, UpdateMallDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminMallService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findMallDto: FindMallDto) {
    return await this.httpService.get<PageResultDto<MallDto>>(`/admin/v1/malls`, findMallDto)
  }

  async create(createMallDto: CreateMallDto) {
    return await this.httpService.post<MallDto>(`/admin/v1/malls`, createMallDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<MallDto>(`/admin/v1/malls/${id}`)
  }

  async findMain() {
    return await this.httpService.get<MallDto>(`/admin/v1/malls/main`)
  }

  async update(updateMallDto: UpdateMallDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/malls/main`, updateMallDto)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/malls/sort-order`, { ids })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/malls/${id}`)
  }

  async createAdmin(id: string, createMallAdminDto: CreateMallAdminDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/malls/${id}/admin`, createMallAdminDto)
  }

  async updateLogo(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/malls/${id}/logo`, { fileId })
  }

  async updateImage(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/malls/${id}/image`, { fileId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/malls/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/malls/${id}/disable`)
  }

  // async setAdmin(id: string, ownerIds: string[]) {
  //   return await this.httpService.put<Boolean>(`/admin/v1/malls/${id}/admins`, { ownerIds })
  // }
}
