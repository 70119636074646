import { Injectable } from '@angular/core'

import { FindMallCouponDto, PageResultDto, TenantCouponDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class AdminTenantCouponService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindMallCouponDto) {
    return await this.httpService.get<PageResultDto<TenantCouponDto>>(`/admin/v1/tenant-coupons`, findVoucherDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantCouponDto>(`/admin/v1/tenant-coupons/${id}`)
  }

  async approve(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenant-coupons/${id}/approve`)
  }

  async reject(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenant-coupons/${id}/reject`)
  }
}
