import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { HomeSectionTitleUpdateDto, HomeSectionTitleParamKeyDto } from '../dto/home-section-title.dto'

@Injectable()
export class AdminHomeSectionTitleService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<HomeSectionTitleParamKeyDto[]>(`/admin/v1/home-section-title`)
  }

  async findOne(key: string) {
    return await this.httpService.get<HomeSectionTitleUpdateDto>(`/admin/v1/home-section-title/${key}`)
  }

  async update(key: string, { title }: HomeSectionTitleUpdateDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/home-section-title/${key}`, {title})
  }
}
