

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { AdminCreateSurveyAnswerDto, AdminCreateSurveyDto, AdminCreateSurveyQuestionDto, AdminExportSurveyResponseDto, AdminFindSurveyDto, AdminFindSurveyResponseDto, AdminSurveyAnswerDto, AdminSurveyDto, AdminSurveyQuestionDto, AdminSurveyResponseDto, AdminUpdateSurveyAnswerDto, AdminUpdateSurveyDto, AdminUpdateSurveyQuestionDto, PageResultDto } from '../dto'

@Injectable()
export class AdminSurveyService {
  constructor(
    private httpService: HttpService) {
  }
  
  async find(adminFindSurveyDto: AdminFindSurveyDto) {
    return await this.httpService.get<PageResultDto<AdminSurveyDto>>(`/admin/v1/surveys`, adminFindSurveyDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminSurveyDto>(`/admin/v1/surveys/${id}`)
  }

  async findResponse(id: string, adminFindSurveyResponseDto: AdminFindSurveyResponseDto) {
    return await this.httpService.get<PageResultDto<AdminSurveyResponseDto>>(`/admin/v1/surveys/${id}/responses`, adminFindSurveyResponseDto)
  }

  async findOneResponse(id: string, responseId: string) {
    return await this.httpService.get<AdminSurveyResponseDto>(`/admin/v1/surveys/${id}/responses/${responseId}`)
  }

  async export(id: string, adminExportSurveyResponseDto: AdminExportSurveyResponseDto) {
    return await this.httpService.get<Boolean>(`/admin/v1/surveys/${id}/export`, adminExportSurveyResponseDto)
  }

  async proceed(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/surveys/${id}/proceed`)
  }

  async create(adminCreateSurveyDto: AdminCreateSurveyDto) {
    return await this.httpService.post<AdminSurveyDto>(`/admin/v1/surveys`, adminCreateSurveyDto)
  }

  async update(id: string, adminUpdateSurveyDto: AdminUpdateSurveyDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/surveys/${id}`, adminUpdateSurveyDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/surveys/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/surveys/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/surveys/${id}/disable`)
  }

  async findQuestion(id: string) {
    return await this.httpService.get<AdminSurveyQuestionDto[]>(`/admin/v1/surveys/${id}/questions`)
  }

  async deleteQuestion(id: string, questionId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/surveys/${id}/questions/${questionId}`)
  }

  async deleteAnswer(id: string, answerId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/surveys/${id}/answers/${answerId}`)
  }

  async sortOrderQuestion(id: string, questionIds: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/surveys/${id}/questions/sort-order`, { surveyQuestionIds: questionIds })
  }

  async sortOrderAnswer(id: string, answerIds: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/surveys/${id}/answers/sort-order`, { surveyAnswerIds: answerIds })
  }

  async createAnswer(id: string, adminCreateSurveyAnswerDto: AdminCreateSurveyAnswerDto) {
    return await this.httpService.post<AdminSurveyAnswerDto>(`/admin/v1/surveys/${id}/answers`, adminCreateSurveyAnswerDto)
  }

  async updateAnswer(id: string, answerId: string, adminUpdateSurveyAnswerDto: AdminUpdateSurveyAnswerDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/surveys/${id}/answers/${answerId}`, adminUpdateSurveyAnswerDto)
  }

  async findOneQuestion(id: string, questionId: string) {
    return await this.httpService.get<AdminSurveyQuestionDto>(`/admin/v1/surveys/${id}/questions/${questionId}`)
  }

  async findAnswers(id: string, questionId: string) {
    return await this.httpService.get<AdminSurveyAnswerDto[]>(`/admin/v1/surveys/${id}/questions/${questionId}/answers`)
  }

  async createQuestion(id: string, adminCreateSurveyQuestionDto: AdminCreateSurveyQuestionDto) {
    return await this.httpService.post<AdminSurveyQuestionDto>(`/admin/v1/surveys/${id}/questions`, adminCreateSurveyQuestionDto)
  }

  async updateQuestion(id: string, questionId: string, adminUpdateSurveyQuestionDto: AdminUpdateSurveyQuestionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/surveys/${id}/questions/${questionId}`, adminUpdateSurveyQuestionDto)
  }
}
