import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateFloorMapDto, CreateMallAdminDto, CreateMallDto, FindFloorMapDto, FindMallDto, FloorMapDto, MallDto, PageResultDto, UpdateMallDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminFloorMapService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findFloorMapDto: FindFloorMapDto) {
    return await this.httpService.get<PageResultDto<FloorMapDto>>(`/admin/v1/floors-map`, findFloorMapDto)
  }

  async create(createFloorMapDto: CreateFloorMapDto) {
    return await this.httpService.post<FloorMapDto>(`/admin/v1/floors-map`, createFloorMapDto)
  }

  async sortOrder(floorMapIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/floors-map/sort-order`, { floorMapIds })
  }

  async findOne(id: string) {
    return await this.httpService.get<FloorMapDto>(`/admin/v1/floors-map/${id}`)
  }

  async update(id: string, updateFloorMapDto: CreateFloorMapDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/floors-map/${id}`, updateFloorMapDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/floors-map/${id}`)
  }

  async updateMobileImage(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/floors-map/${id}/mobile-image`, { fileId })
  }

  async updateImage(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/floors-map/${id}/image`, { fileId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/floors-map/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/floors-map/${id}/disable`)
  }

  async setTenants(id: string, tenantIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/floors-map/${id}/tenants`, { tenantIds })
  }
}
