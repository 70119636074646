import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreatePushNotificationDto, FindPushNotificationDto, NotificationLogDto, PageResultDto, PushNotificationDto } from '../dto'
import { PagingDto } from '@api/dto/paging.dto'

@Injectable()
export class AdminPushNotificationService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: FindPushNotificationDto) {
    return await this.httpService.get<PageResultDto<PushNotificationDto>>(`/admin/v1/push-notification`, { limit, offset })
  }

  async create({ title, message, creator, link }: CreatePushNotificationDto) {
    return await this.httpService.post<boolean>('/admin/v1/push-notification', { title, message, creator, link })
  }

  async findLog({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<NotificationLogDto>>(`/admin/v1/notifications/logs`, { limit, offset })
  }
}
