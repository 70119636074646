import { Injectable } from "@angular/core"

import { PageResultDto, CollectionTenantDto, AdminFindFlashSaleDto, AdminFlashSaleDto, AdminFindFlashSaleItemDto, AdminFlashSaleItemDto, AdminCreateFlashSaleDto, AdminUpdateFlashSaleDto } from "../dto"
import { HttpService } from "./http.service"

@Injectable()
export class AdminFlashSaleService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: AdminFindFlashSaleDto) {
    return await this.httpService.get<PageResultDto<AdminFlashSaleDto>>('/admin/v1/flash-sale', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminFlashSaleDto>(`/admin/v1/flash-sale/${id}`)
  }

  async findItems(id: string, adminFindFlashSaleItemDto: AdminFindFlashSaleItemDto) {
    return await this.httpService.get<PageResultDto<AdminFlashSaleItemDto>>(`/admin/v1/flash-sale/${id}/items`, adminFindFlashSaleItemDto)
  }

  async update(id: string, adminUpdateFlashSaleDto: AdminUpdateFlashSaleDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/flash-sale/${id}`, adminUpdateFlashSaleDto)
  }

  async create(adminCreateFlashSaleDto: AdminCreateFlashSaleDto) {
    return await this.httpService.post<AdminFlashSaleDto>(`/admin/v1/flash-sale`, adminCreateFlashSaleDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/flash-sale/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/flash-sale/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/flash-sale/${id}/disable`)
  }

  async findTenants(id: string) {
    return await this.httpService.get<CollectionTenantDto[]>(`/admin/v1/flash-sale/${id}/tenants`)
  }

  async approveAll(id: string, tenantId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/flash-sale/${id}/approve-all`, { tenantId })
  }

  async approve(id: string, productIds: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/flash-sale/${id}/approve`, { productIds })
  }

  async reject(id: string, productIds: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/flash-sale/${id}/reject`, { productIds })
  }
}