import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { PageResultDto, ProductDto, FindProductDto, ProductDetailDto, CreateOrUpdateProductDto, SetFileDto, SetFilesDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class ProductService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findClientProduct(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/products/clients`, findProductDto)
  }

  async findAllClientProduct(discounted: string) {
    return await this.httpService.get<ProductDto[]>(`/merchant/v1/products/all-clients`, { discounted })
  }

  async createOrUpdate(createOrUpdateProductDto: CreateOrUpdateProductDto[]) {
    return await this.httpService.put<ProductDto>(`/merchant/v1/products`, createOrUpdateProductDto)
  }

  async find(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/products`, findProductDto)
  }

  async publish(id: string) {
    return await this.httpService.post(`/merchant/v1/products/${id}/publish`)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductDetailDto>(`/merchant/v1/products/${id}`)
  }

  async deleteOne(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/products/${id}`)
  }

  async setPreSelected(productId: string, preSelectedId: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/products/${productId}/pre-selected`, { preSelectedId })
  }

  async setSizeChart(productId: string, id: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/products/${productId}/size-chart`, { sizeChartNameId: id })
  }

  async setThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/thumbnail`, setFileDto)
  }

  async setSizeImage(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/size-guide-image`, setFileDto)
  }

  async setColorThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/color-thumbnail`, setFileDto)
  }

  async setImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/images`, setFilesDto)
  }

  async setSpecsImage(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/merchant/v1/products/${productId}/specs-images`, setFilesDto)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/merchant/v1/products/${id}/enable`)
    }
    
    return await this.httpService.put<Boolean>(`/merchant/v1/products/${id}/disable`)
  }

  async campaign(productId: string) {
    return await this.httpService.get<boolean>(`/merchant/v1/products/${productId}/campaign`)
  }
}
