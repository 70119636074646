import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateProductCategoryDto, FindChildrenProductCategoryDto, FindProductCategoryDto, PageResultDto, ProductCategoryDto, UpdateProductCategoryDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminProductCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<ProductCategoryDto[]>(`/admin/v1/product-categories`)
  }

  async create(createProductCategoryDto: CreateProductCategoryDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/product-categories`, createProductCategoryDto)
  }

  async findChildren(findChildrenProductCategoryDto: FindChildrenProductCategoryDto) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>(`/admin/v1/product-categories/last-children`, findChildrenProductCategoryDto)
  }

  async findParent(findChildrenProductCategoryDto: FindChildrenProductCategoryDto) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>(`/admin/v1/product-categories/parent`, findChildrenProductCategoryDto)
  }

  async findParentOnly(findChildrenProductCategoryDto: FindChildrenProductCategoryDto) {
    return await this.httpService.get<ProductCategoryDto[]>(`/admin/v1/product-categories/parent-only`, findChildrenProductCategoryDto)
  }

  async update(id: string, updateProductCategoryDto: UpdateProductCategoryDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/product-categories/${id}`, updateProductCategoryDto)
  }

  async updatePromotionBanner(id: string, updateProductCategoryDto: UpdateProductCategoryDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/product-categories/${id}/promotion-banner`, updateProductCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/product-categories/${id}`)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductCategoryDto>(`/admin/v1/product-categories/${id}`)
  }
}
