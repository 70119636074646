import { Injectable } from '@angular/core'

import { FindOrderDto, OrderDto, PageResultDto } from '../dto'
import { CountStatusDto, ExportOrderDto, OrderDetailDto, OrderQuantityDto, OrderRejectDto, UpdateOrderQuantityItemDto } from '../dto/order.dto'
import { HttpService } from './http.service'


@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService
  ) {}

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/merchant/v1/orders', findOrderDto)
  }

  async countStatus() {
    return await this.httpService.get<CountStatusDto>('/merchant/v1/orders/count')
  }

  async exportExcel(dateFilterDto: ExportOrderDto) {
    return await this.httpService.get<any>(`/merchant/v1/orders/export`, dateFilterDto)
  }

  async findOne(tenantOrderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/merchant/v1/orders/${tenantOrderNumber}`)
  }

  async accept(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/accept`)
  }

  async complete(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/complete`)
  }

  async shipping(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/shipping`)
  }

  async reject(tenantOrderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/reject`, { reason })
  }

  async readyPickup(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/ready-pickup`)
  }

  async findUpdateQuantity(tenantOrderId: string) {
    return await this.httpService.get<OrderQuantityDto[]>(`/merchant/v1/order-item-stock-adjustment`, { tenantOrderId })
  }

  async updateQuantity(tenantOrderId: string, items: UpdateOrderQuantityItemDto[]) {
    return await this.httpService.post(`/merchant/v1/order-item-stock-adjustment`, { tenantOrderId, items })
  }
}