import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminWhitelistPaywayMidDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminWhitelistPaywayMidService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async whitelist(adminWhitelistPaywayMidDto: AdminWhitelistPaywayMidDto) {
    return await this.httpService.post<any>(`/admin/v1/whitelist-mid`, adminWhitelistPaywayMidDto)
  }
}
