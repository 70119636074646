import { Routes } from '@angular/router'

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: './+merchant#MerchantModule'
  },
  {
    path: 'admin',
    loadChildren: './+admin#AdminModule'
  }
]
