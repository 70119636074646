import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateHeaderPromotionDto, HeaderPromotionDto, PageResultDto, UpdateHeaderPromotionDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminHeaderPromotionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<HeaderPromotionDto>>(`/admin/v1/header-promotion`, { limit, offset })
  }

  async update(id: string, updateBannerOnboardDto: UpdateHeaderPromotionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/header-promotion/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerPopupDto: CreateHeaderPromotionDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/header-promotion`, createBannerPopupDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/header-promotion/${id}`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/header-promotion/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/header-promotion/${id}/disable`)
  }
}
