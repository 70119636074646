import { Injectable } from "@angular/core"

import { PageResultDto, CollectionDto, CollectionItemDto, UpdateCollectionDto, CreateCollectionDto, FindCollectionDto, CollectionTenantDto, FindCollectionItemDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class AdminCollectionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: FindCollectionDto) {
    return await this.httpService.get<PageResultDto<CollectionDto>>('/admin/v1/collections', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<CollectionDto>(`/admin/v1/collections/${id}`)
  }

  async findItems(id: string, findCollectionItemDto: FindCollectionItemDto) {
    return await this.httpService.get<PageResultDto<CollectionItemDto>>(`/admin/v1/collections/${id}/items`, findCollectionItemDto)
  }

  async update(id: string, updateBeginnerGuideDto: UpdateCollectionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/collections/${id}`, updateBeginnerGuideDto)
  }

  async create(createBeginnerGuideDto: CreateCollectionDto) {
    return await this.httpService.post<CollectionDto>(`/admin/v1/collections`, createBeginnerGuideDto)
  }

  async finish(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/collections/${id}/finish`)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/collections/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/collections/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/collections/${id}/disable`)
  }

  async findTenants(id: string) {
    return await this.httpService.get<CollectionTenantDto[]>(`/admin/v1/collections/${id}/tenants`)
  }

  async sortOrder(ids: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/collections/sort-order`, { ids })
  }

  async approveAll(id: string, tenantId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/collections/${id}/approve-all`, { tenantId })
  }

  async approve(id: string, productIds: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/collections/${id}/approve`, { productIds })
  }

  async reject(id: string, productIds: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/collections/${id}/reject`, { productIds })
  }
}