import { Injectable } from '@angular/core'

import { HttpService } from './http.service'


@Injectable()
export class MallService {
  constructor(
    private httpService: HttpService
  ) {}

}