import { Injectable } from '@angular/core'

import { CreateTenantCouponDto, ExportHistoryDto, FindTenantCouponDto, PageResultDto, TenantCouponDto, TenantCouponHistoryDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class TenantCouponService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindTenantCouponDto) {
    return await this.httpService.get<PageResultDto<TenantCouponDto>>(`/merchant/v1/tenant-coupons`, findVoucherDto)
  }

  async findHistory(pagingDto: FindTenantCouponDto) {
    return await this.httpService.get<PageResultDto<TenantCouponHistoryDto>>(`/merchant/v1/tenant-coupons/history`, pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantCouponDto>(`/merchant/v1/tenant-coupons/${id}`)
  }

  async exportCode(id: string) {
    return await this.httpService.get<any>(`/merchant/v1/tenant-coupons/${id}/export-code`)
  }

  async exportReport(exportHistoryDto: ExportHistoryDto) {
    return await this.httpService.get<any>(`/merchant/v1/tenant-coupons/export-report`, exportHistoryDto)
  }

  async create(createVoucherDto: CreateTenantCouponDto) {
    return await this.httpService.post<TenantCouponDto>('/merchant/v1/tenant-coupons', createVoucherDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/tenant-coupons/${id}`)
  }
}
