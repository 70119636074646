import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminCreateFrequentlyAskedQuestionDto, AdminFindFrequentlyAskedQuestionDto, AdminFrequentlyAskedQuestionDto, AdminUpdateFrequentlyAskedQuestionDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminFrequentlyAskedQuestionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(adminFindFrequentlyAskedQuestionDto: AdminFindFrequentlyAskedQuestionDto) {
    return await this.httpService.get<AdminFrequentlyAskedQuestionDto[]>(`/admin/v1/frequently-asked-questions`, adminFindFrequentlyAskedQuestionDto)
  }

  async create(adminCreateFrequentlyAskedQuestionDto: AdminCreateFrequentlyAskedQuestionDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/frequently-asked-questions`, adminCreateFrequentlyAskedQuestionDto)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/frequently-asked-questions/sort-order`, { ids })
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminFrequentlyAskedQuestionDto>(`/admin/v1/frequently-asked-questions/${id}`)
  }

  async update(id: string, adminUpdateFrequentlyAskedQuestionDto: AdminUpdateFrequentlyAskedQuestionDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/frequently-asked-questions/${id}`, adminUpdateFrequentlyAskedQuestionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/frequently-asked-questions/${id}`)
  }
}
