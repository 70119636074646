import { Injectable } from "@angular/core"

import { PageResultDto, MerchantCollectionDto, MerchantCollectionItemDto, MerchantDeleteCollectionItemDto, MerchantFindCollectionDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"
import { MerchantCreateCollectionItemDto } from "../dto/collection.dto"


@Injectable()
export class CollectionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: MerchantFindCollectionDto) {
    return await this.httpService.get<PageResultDto<MerchantCollectionDto>>('/merchant/v1/collections', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<MerchantCollectionDto>(`/merchant/v1/collections/${id}`)
  }

  async findItems(id: string, pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<MerchantCollectionItemDto>>(`/merchant/v1/collections/${id}/items`, pagingDto)
  }

  async createItems(id: string, { isAll, productIds }: MerchantCreateCollectionItemDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/collections/${id}/items`, { isAll, productIds })
  }

  async deleteItem(id: string, { productIds }: MerchantDeleteCollectionItemDto) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/collections/${id}/items`, { productIds })
  }

  async leave(id: string) {
    return await this.httpService.post<Boolean>(`/merchant/v1/collections/${id}/leave`)
  }
}
