import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'
import { DragulaService } from 'ng2-dragula'


import { HttpService, AuthService, LocalStorageService, FileService, ProfileService, SettingService, AdminTenantService, AdminUserService, AdminMallService, AdminProductCategoryService, AdminTenantCategoryService, AdminBannerOnboardService, AdminDashboardService, ProductService, OrderService, MallService, ProductCategoryService, ProductDiscountService, AdminProductDiscountService, AdminFeaturedTenantCategoryService, AdminFeaturedTenantService, InventoryService, DashboardService, AdminCustomerInquiryService, AdminFloorMapService, AdminPromotionService, AdminGalleryService, AdminBannerService, AdminMenuService, TenantService, AdminProductReviewService, ProductReviewService, FloorMapService, AdminProductService, AdminMallVoucherService, TenantVoucherService, AdminPickupCounterService, AdminMallServiceMenuService, AdminMallOrderService, AdminPageService, AdminTrendingSearchService, WindowRef, AdminMallServicePageService, AdminTenantVoucherService, AdminPushNotificationService, AdminReportService, MerChantReportSerive, AdminHomeSectionTitleService, AdminWhitelistPaywayMidService, AdminMallCouponService, AdminTenantCouponService, TenantCouponService, AdminGlobalSettingService, AdminLocationService, AdminShippingService, AdminBannerPopupService, AdminHeaderPromotionService, AdminFrequentlyAskedQuestionService, AdminBeginnerGuideService, AdminCollectionService, CollectionService, AdminVoucherCampaignService, AdminSizeChartService, SizeChartService, AdminFlashSaleService, FlashSaleService, AdminPageContentService, AdminAppVersionService, AdminSurveyService } from '@api/services'

import { ApiModuleOptions } from './api.module-options'
import { NgxChartsModule } from '@swimlane/ngx-charts'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    AdminUserService,
    AdminMallService,
    AdminTenantService,
    AdminProductCategoryService,
    AdminTenantCategoryService,
    AdminDashboardService,
    AdminPageService,
    AdminBannerOnboardService,
    AdminProductDiscountService,
    AdminFeaturedTenantCategoryService,
    AdminProductReviewService,
    AdminFeaturedTenantService,
    AdminPushNotificationService,
    AdminTrendingSearchService,
    AdminBannerService,
    AdminFloorMapService,
    AdminTenantVoucherService,
    AdminCustomerInquiryService,
    AdminMallOrderService,
    AdminPromotionService,
    AdminProductService,
    AdminMallVoucherService,
    AdminMenuService,
    AdminMallServiceMenuService,
    AdminMallServicePageService,
    AdminGalleryService,
    AdminPickupCounterService,
    AdminHomeSectionTitleService,
    AdminWhitelistPaywayMidService,
    AdminReportService,
    AdminMallCouponService,
    AdminTenantCouponService,
    AdminGlobalSettingService,
    AdminShippingService,
    AdminLocationService,
    AdminBannerPopupService,
    AdminHeaderPromotionService,
    AdminFrequentlyAskedQuestionService,
    AdminBeginnerGuideService,
    AdminCollectionService,
    AdminVoucherCampaignService,
    AdminSizeChartService,
    AdminFlashSaleService,
    AdminPageContentService,
    AdminAppVersionService,
    AdminSurveyService,
    MerChantReportSerive,
    ProfileService,
    ProductReviewService,
    SettingService,
    ProductService,
    TenantService,
    TenantVoucherService,
    OrderService,
    DragulaService,
    FloorMapService,
    WindowRef,
    MallService,
    ProductCategoryService,
    ProductDiscountService,
    TenantCouponService,
    InventoryService,
    DashboardService,
    CollectionService,
    SizeChartService,
    FlashSaleService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
